<template>
  <div class="wrapper secondBg border-radius-10">
    <v-card-title
      class="border-radius-top-inherit d-flex justify-end success py-2 pr-2 pl-4"
    >
      <v-icon
        v-if="viewToggle"
        class="close-btn white--text"
        @click="onPopUpClose"
      >
        mdi-close
      </v-icon>
      <v-icon
        v-else
        class="close-btn white--text"
        @click="onPopUpClose"
      >
        mdi-alert-octagram
      </v-icon>
    </v-card-title>
    <v-subheader
      class="text--text height-auto body-1 text-md-h6 text-center d-block mb-3"
    >
      <h3 class="text--text " >{{ title }}</h3>
    </v-subheader>
    <div
      class="wrapper bg-secondary mx-auto col-10 col-lg-8 col-xl-6 px-0 mb-0"
    >
      <h4 class="bg-success header text-center px-2 py-2 py-xl-3 font-weight-normal">
        {{innerText}}
      </h4>
      <div class="mx-0 button-wrapper justify-content-center">
        <p class="divider col-12 mb-0"></p>
        <v-btn
          v-if="buttonShowToggle"
          rounded
          color="success"
          class="body-2 innerButtonSt"
          @click="notificationBtnHandler"
        >
          {{ buttonName }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomNotification',
  components: { },
  data() {
    return {
      viewToggle: false,
    };
  },
  props: {
    title: String,
    innerText: String,
    buttonShowToggle: Boolean,
    buttonName: String,
    pathForRedirect: String,
  },
  methods: {
    onPopUpClose() {
      this.$emit('onPopUpClose');
    },
    notificationBtnHandler() {
      this.$router.push({ path: '/' });
      this.onPopUpClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 90vw;
  @media screen and (min-width: 600px) {
    width: 500px;
  }
}

.list-wrapper {
  max-height: 80vh;
}
.button-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
