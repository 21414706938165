<template>
  <div class="wrapper secondBg border-radius-10">
    <v-card-title
      class="border-radius-top-inherit d-flex justify-end success py-2 pr-2 pl-4"
    >
      <v-icon
        class="close-btn white--text"
        @click="onPopUpClose"
      >
        mdi-close
      </v-icon>
    </v-card-title>
    <v-subheader class="text--text d-flex justify-center subtitle-1 font-weight-medium">
      Мої платежі по кредиту
    </v-subheader>

    <v-card-text class="px-0 pb-0 list-wrapper custom-scroll border-radius-10 position-relative">
      <block-preloader
        v-if="isLoading"
        background-color="secondBg"
      />
      <v-list v-if="payments && payments.length > 0"
              class="border-radius-inherit secondBg pa-0 px-1 px-sm-2">
        <v-list-item
          v-for="(payment, index) of payments"
          :key="index"
          :class="['pa-0 body-2 justify-space-between px-2 px-sm-3',
                   { 'bottom': index === 3},
                   $vuetify.theme.dark ? 'item-border-dark' : 'item-border-light']"
        >
          <div class="pa-0 font-weight-medium col-7 caption text-sm-body-2">
            Дата платежу:
            <span class="font-weight-regular">
              {{ payment.created_at | formatDate }}
            </span>
          </div>
          <div class="pa-0 font-weight-medium col-5 text-end caption text-sm-body-2">
            Сума:
            <span class="font-weight-regular">
              {{ payment.payment_sum }}₴
            </span>
          </div>
        </v-list-item>
      </v-list>
      <div v-else>
        <p
          :class="['text-center py-2 body-2',
                       $vuetify.theme.dark ? 'item-border-dark' : 'item-border-light']"
        >
          Ви ще не робили платежів по цьому кредиту
        </p>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import BlockPreloader from '../../components/common/BlockPreloader';

export default {
  name: 'PaymentHistory',
  components: { BlockPreloader },
  filters: {
    formatDate: (d) => {
      // eslint-disable-next-line no-param-reassign
      d = new Date(d);
      return d.toLocaleString('ru-RU').replace(',', '').slice(0, -3);
    },
  },
  props: {
    payments: Array,
    isLoading: Boolean,
  },
  methods: {
    onPopUpClose() {
      this.$emit('onPopUpClose');
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 90vw;

  @media screen and (min-width: 600px) {
    width: 500px;
  }
}

.list-wrapper {
  max-height: 80vh;
}
</style>
