<template>
  <v-scale-transition mode="in-out">
    <div
      v-if="isAuthenticated"
    >
      <pop-up-window v-if="paymentsPopUpIsOpen">
        <payment-history
          :payments="paymentHistory[selectedCreditId]"
          :is-loading="paymentsAreLoading"
          @onPopUpClose="togglePaymentsPopUp(false)"
        />
      </pop-up-window>
      <tab-header
        class="item col-12 mx-auto mx-md-0 mb-2"
        icon-name="archive-outline"
        text="Історія"
      />
      <div
        v-if="credits.length > 0"
        class="pa-0"
      >
        <div
          v-for="(item, index) of credits"
          :key="index"
          class="d-flex border-red border-radius-10 flex-column
           pt-3 pb-5 px-2 px-sm-5 item mx-auto
                pt-sm-4 pb-sm-7 flex-md-row px-md-16 px-lg-10"
          :class="{ 'mt-8': index > 0 }"
        >
          <v-col
            v-if="creditsHistoryLoading"
            class="wrapperPreloader d-flex flex-column"
          >
            <block-preloader />
          </v-col>
          <v-col
            v-else
            class="pa-0 d-flex flex-column align-center align-md-start justify-space-between"
          >
            <div>
              <v-subheader
                class="error--text text-h6 pa-0 height-auto d-flex justify-center
                                  justify-md-start"
              >
                <!--                Cash на картку!-->
                {{ products[item.product] || item.product }}
              </v-subheader>
              <v-list class="d-flex flex-column align-center align-md-start mt-md-2">
                <v-list-item class="height-auto font-weight-medium pa-0 mb-1 mb-md-2">
                  Договір:
                  <span class="font-weight-regular ml-1">
                    {{ item.credit_id }}
                  </span>
                </v-list-item>
                <v-list-item class="height-auto font-weight-medium pa-0 mb-1 mb-md-2">
                  Дата видачі:
                  <span class="font-weight-regular ml-1">
                    {{ item.credit_start | formatDate }}
                  </span>
                </v-list-item>
                <v-list-item class="height-auto font-weight-medium pa-0 mb-1 mb-md-2">
                  Сума кредиту:
                  <span class="font-weight-regular ml-1">
                   {{ item.credit_value | sumFormatter }} ₴
                  </span>
                </v-list-item>
                <v-list-item class="height-auto font-weight-medium pa-0 mb-1 mb-md-2">
                  Термін кредиту:
                  <span class="font-weight-regular ml-1">
                    {{ item.credit_term }} міс.
                  </span>
                </v-list-item>
                <v-list-item
                  v-if="!item.is_active"
                  class="height-auto font-weight-medium pa-0"
                >
                  Дата погашення:
                  <span class="font-weight-regular ml-1">
                    {{ item.credit_end | formatDate }}
                  </span>
                </v-list-item>
              </v-list>
            </div>
            <v-btn
              v-if="getCreditContract(item.order_id)"
              rounded
              outlined
              color="error"
              class="caption mt-5 "
              :href="getCreditContract(item.order_id).viewUrl"
              target="_blank"
            >
              {{ getCreditContract(item.order_id).name }}
            </v-btn>
          </v-col>
          <notification
            v-if="waitNotification"
            type="success"
          >
            Довідка про відсутність заборгованості формується, очікуйте
          </notification>
          <v-col
            v-if="!creditsHistoryLoading"
            class="buttonsWrapper"
          >
            <v-btn
              rounded
              elevation="5"
              color="normal"
              class="caption mt-5 mb-16 float-right historyBtn1"
              target="_blank"
              @click="getPaymentsForCredit(item.credit_id); togglePaymentsPopUp()"
            >
              <!--              Дивитися історію платежів по кредиту-->
              історія платежів
              <v-icon
                right
                dark
              >
                mdi-clipboard-text-clock
              </v-icon>
            </v-btn>
            <v-btn
              v-if="!item.is_active"
              elevation="5"
              rounded
              color="secondary"
              class="caption mt-5 float-right historyBtn2"
              target="_blank"
              @click="onClickHandlerRequestFinalization(item.credit_id)"
            >
              <!--              Замовити довідку про закриття заборгованості-->
              довідка про закриття
              <div class="icon_loader_wrapper">
                <v-progress-circular
                  v-if="loading === item.credit_id"
                  indeterminate
                  class="align-self-center"
                  style=" max-width: 23px"
                />
                <v-icon
                  right
                  dark
                  style="padding-top:5px;
                  margin-right: 6px;
                  margin-bottom: 3px"
                  v-else
                >
                  mdi-application-edit-outline
                </v-icon>
              </div>
            </v-btn>
            <pop-up-window v-if="showNotification">
              <CustomNotification
                title="Ваш запит отримано."
                inner-text="Довідку замовлено, очікуйте"
              />
            </pop-up-window>
          </v-col>
        </div>
      </div>
      <div
        v-else
        class="no-history mx-auto"
      >
        <p class="text-h6 text--text">
          У Вас поки немає історії кредитів
        </p>
      </div>
    </div>
  </v-scale-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TabHeader from '@/components/common/TabHeader';
import PopUpWindow from '@/components/common/PopUpWindow';
import PaymentHistory from '@/views/credit-history/PaymentHistory';
import pageLoaderMixin from '../../mixins/pageLoaderMixin';
import CustomNotification from '../../components/common/CustomNotification';
import BlockPreloader from '../../components/common/BlockPreloader';
import Notification from '../../components/common/Notification';
import downloadDocServiceMixin from '../../mixins/downloadDocServiceMixin';
import { ClientInfoApi } from '../../api/Rest';

export default {
  name: 'CreditHistory',
  components: {
    CustomNotification,
    PaymentHistory,
    PopUpWindow,
    TabHeader,
    BlockPreloader,
    Notification,
  },
  title: 'Історія',
  filters: {
    formatDate: (d) => {
      // eslint-disable-next-line no-param-reassign
      d = new Date(d);
      return d.toLocaleString('ru-RU').replace(',', '').slice(0, -8);
    },
  },
  mixins: [pageLoaderMixin, downloadDocServiceMixin],
  data() {
    return {
      selectedCreditId: '',
      paymentsPopUpIsOpen: false,
      paymentsAreLoading: false,
      paymentHistory: {},
      loanAgreements: {},
      showNotification: false,
      showLoanPaymentsPopUp: false,
      creditsHistoryLoading: false,
      waitNotification: false,
      loading: '',
    };
  },
  computed: {
    ...mapGetters([
      'getCreditPassport',
      'getCreditContract',
    ]),
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    products: {
      get() {
        return this.$store.state.products;
      },
    },
    credits: {
      get() {
        return this.$store.state.clientInfo.clientData.credits;
      },
      set(value) {
        this.$store.commit('clientInfo/UPDATE_FORM_DATA', {
          parentObjName: 'clientData',
          attribute: 'credits',
          value,
        });
      },
    },
  },
  beforeMount() {
    this.hidePagePreloader();
    this.creditsHistoryLoading = true;
    this.getCreditsStore()
      .then(() => {
        if (this.credits.length > 0) {
          this.getAllCreditPaymentsAndLoanAgreements();
          this.getAttachedFilesStore();
          this.getProductsStore();
          this.creditsHistoryLoading = false;
        }
      })
      .catch(() => {
        this.isUnsuccess = true;
      });
  },
  beforeDestroy() {
    // this.credits = [];
  },
  methods: {
    ...mapActions('clientInfo', [
      'getCreditsStore',
      'getPaymentHistoryStore',
    ]),
    ...mapActions([
      'getAttachedFilesStore',
      'getProductsStore',
    ]),
    getPaymentsForCredit(creditId) {
      this.paymentsAreLoading = true;
      this.selectedCreditId = creditId;
      this.getPaymentHistoryStore(creditId)
        .then((result) => {
          this.$set(this.paymentHistory, creditId, result);
          this.paymentsAreLoading = false;
        })
        .catch(() => {});
    },
    getAllCreditPaymentsAndLoanAgreements() {
      this.credits.forEach((item) => {
        this.getPaymentsForCredit(item.credit_id);
      });
    },
    togglePaymentsPopUp(bool = true) {
      this.paymentsPopUpIsOpen = bool;
    },
    onClickHandlerHistory() {
      this.showLoanPaymentsPopUp = !this.showLoanPaymentsPopUp;
    },
    onClickHandlerRequestFinalization(creditId) {
      this.loading = creditId;
      ClientInfoApi.getCreditReference(creditId)
        .then((res) => {
          this.loading = '';
          const result = res.payload;
          if (result.is_found === true && result.token === '') {
            this.hidePagePreloader();
            this.waitNotification = true;
            this.showPopUpWindowMethod('waitNotification');
          } else if (result.token !== '' && result.token.length > 1) {
            this.downloadDoc(result.token);
          } else {
            this.showNotification = true;
            this.showPopUpWindowMethod('showNotification');
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    sendCreditInformationRequest(x) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(x);
        }, 2000);
      });
    },
    showPopUpWindowMethod(notificationState) {
      switch (notificationState) {
        case 'waitNotification':
          setTimeout(() => {
            this.waitNotification = false;
          }, 3000);
          break;
        case 'showNotification':
          setTimeout(() => {
            this.showNotification = false;
          }, 3000);
          break;
        default:
          this.showNotification = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item-border {
  &-light {
    border-top: 1px solid #000;

    &.bottom {
      border-bottom: 1px solid #000;
    }
  }

  &-dark {
    border-top: 1px solid #fff;

    &.bottom {
      border-bottom: 1px solid #fff;
    }
  }

  &:after {
    content: none;
  }
}

.item {
  @media screen and (max-width: 960px) {
    @media screen and (min-width: 450px) {
      width: 400px;
    }

    @media screen and (min-width: 600px) {
      width: 450px;
    }
  }
}

.no-history {
  @media screen and (max-width: 960px) {
    @media screen and (min-width: 400px) {
      max-width: 390px;
    }

    @media screen and (min-width: 600px) {
      width: 445px;
    }
  }
}
.notificationPosition {
  bottom: -5em;
  right: 5em;
}
.wrapperPreloader{
  position: relative;
  padding: 10%;
}
.icon_loader_wrapper{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
