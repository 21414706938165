import { getAccessToken } from '../api/Rest';

const { VUE_APP_BACKEND_API_URL } = process.env;

export default {
  methods: {
    downloadDoc(token) {
      window.location.href = `${VUE_APP_BACKEND_API_URL}api/v1/files/file_download/${token}?access=${getAccessToken()}`;
    },
  },
};
